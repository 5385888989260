import { useState } from 'react';
// LIBRARIES

// MATERIAL
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';

// COMPONENTS
import SearchBar from '../components/SearchBar';
import DoorCard from '../components/DoorCard';
import { PermissionError } from '../components/PermissionError';

// FUNCTIONS
import { usePosition } from '../hooks/usePosition';
import { useDevicesQuery } from '../hooks/useDeviceQuery';

export default function Dashboard(props) {
  const { latitude, longitude, positionError } = usePosition();

  const { devices, isLoading, isFetching, error, handleDeviceFilters, deviceFilter } =
    useDevicesQuery({ lat: latitude, lng: longitude });


  const [searchText, setSearchText] = useState('');
  const handleSearchText = (text) => {
    setSearchText(text);
  };

  return (
    <div>
      {positionError ? (
        <PermissionError serviceName='location' />
      ) : (
        <>
          <SearchBar
            searchText={searchText}
            handleText={handleSearchText}
            onRefresh={() => setSearchText('')}
            handleFilters={handleDeviceFilters}
            filters={deviceFilter}
          />
          {isLoading === 'loading' && (
            <LinearProgress color='primary' sx={{ marginBottom: 2 }} />
          )}
          <Grid container spacing={1}>
            {devices &&
              devices.length > 0 &&
              devices
                .filter((row) => {
                  return row.deviceName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
                })
                .map((device, index) => (
                  <Grid xs={6} md={6} item key={index} style={{ display: 'flex' }}>
                    <DoorCard device={device} />
                  </Grid>
                ))}
          </Grid>
        </>
      )}
    </div>
  );
}
