import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';

// LIBRARIES
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { API } from 'aws-amplify';
import { Flex, Image, Heading } from '@aws-amplify/ui-react';

// MATERIAL
import { Typography } from '@mui/material';

// COMPONENTS
import Scan from './Scan';

// ASSETS
import logo192 from '../assets/logo192.png';

function Header() {
  const headerText = 'Powered by ScanseQR';
  const [charIndex, setCharIndex] = useState(1);

  useEffect(() => {
    if (charIndex < headerText.length) {
      setTimeout(() => setCharIndex(charIndex + 1), 100);
    }
  }, [charIndex]);

  return (
    <Flex justifyContent='center' alignItems={'center'} direction={'column'}>
      <Image alt='logo' src={logo192} style={{ paddingTop: 20 }} />
      <Heading
        level={3}
        style={{
          marginBottom: 15,
          fontFamily: 'Courier New',
          color: '#ff9903',
        }}
      >
        {headerText.slice(0, charIndex)}
      </Heading>
    </Flex>
  );
}

const validateKey = async (postBody) => {
  // First check if key is in local storage
  // If key is in local storage, return it

  const AccessKeys = JSON.parse(localStorage.getItem('AccessKeys') || null) || [];
  // Keys are stored in the follow format:
  // [{ name: Key.name, expiry: Key.endTime, key: postBody.key, devices: [{name: device.name, uuid: device.uuid}...] }]
  const validAccessKeys = AccessKeys.filter((k) => new Date(k.expiry) > new Date());
  // Update the local storage, removing any expired keys
  if (AccessKeys.length !== validAccessKeys.length) {
    localStorage.setItem('AccessKeys', JSON.stringify(validAccessKeys));
  }

  if (validAccessKeys && validAccessKeys.length > 0) {
    const key = validAccessKeys.find((k) => k.key === postBody.key);
    if (key) {
      console.log('LOCAL STORAGE: ', key);
      return { "success": true, ...key };
    }
  }

  const result = await API.post('Scanseqr', 'authentication/validate-device-key', {
    body: postBody,
  }).catch((err) => {
    throw err;
  });
  console.log('API RESULT: ', result);

  // STORE THE KEY IN LOCAL STORAGE
  if (result.success) {
    AccessKeys.push({ name: result.name, expiry: result.endTime, key: postBody.key, devices: result.devices.map((device) => ({ name: device.deviceName, uuid: device.uuid, online: device.online, distanceEnabled: device.distanceEnabled })) });
    localStorage.setItem('AccessKeys', JSON.stringify(AccessKeys));
  }
  return result;
  /*{
    "success": true,
    "name": "G1 Test2",
    "createdBy": "Admin",
    "endTime": "2024-11-27T03:42:00.000Z",
    "devices": [
        {
            "deviceName": "Door",
            "uuid": "309b9286",
            "online": null,
            "distanceEnabled": false
        },
        {
            "deviceName": "Light",
            "uuid": "269d4507",
            "online": null,
            "distanceEnabled": false
        }
    ]
}*/
};

// const actionKey = async (postBody) => {
//   const result = await API.post('Scanseqr', 'authentication/action-device-key', {
//     body: postBody,
//   }).catch((err) => {
//     throw err;
//   });
//   console.log('RESULT: ', result);
//   return result || [];
// };

export default function AccessToken() {
  const { encodedData } = useParams();
  console.log('AccessToken:', encodedData);
  const { state } = useLocation();
  console.log('LOCATION:', state);

  // const mutation = useMutation(actionKey, {
  //   onSuccess: (data) => {
  //     console.log("BOOM: ", data)
  //     window.history.replaceState({}, '')
  //     if (data.result === 'Granted') {
  //       if (data.website) {
  //         window.location.href = data.website;
  //       } else {
  //         // setStatusMessage('Enter now');
  //       }
  //     }
  //   },
  //   onError: (error) => {
  //     // setStatusMessage('ERROR');
  //     console.log(error)
  //   },
  // });


  // Queries
  const { data: deviceKey, status: keyValidated } = useQuery({
    queryKey: ['deviceKey'],
    queryFn: () => validateKey({ key: encodedData }),
    // The query will not execute until follow condition is met
    // The webpage is actually visible and the location state is null, ie. we have not been redirected from scanning a QR code
    enabled: document.visibilityState === 'visible' && state === null
  });

  const [statusMessage, setStatusMessage] = useState('Validating device key');
  const navigate = useNavigate();

  useEffect(() => {
    if (
      keyValidated === 'error' ||
      (keyValidated === 'success' && deviceKey?.success === false)
    ) {
      setStatusMessage('Error validating Access Key');
      const intervalRef = window.setTimeout(() => {
        navigate('/login');
        // window.location.href = 'https://www.scanseqr.com'; // Redirect to ScanseQR.com
      }, 4000);
      return () => {
        window.clearInterval(intervalRef);
      };
    }
    // setStatusMessage(`Scanning for ${deviceKey?.deviceName ? deviceKey.deviceName : 'a '} QR`);
    setStatusMessage('');
  }, [deviceKey, keyValidated, navigate]);

 // ******************************************** NEEDS TESTING *********************************************
 // SHOULD ONLY RUN IF WE HAVE BEEN REDIRECTED FROM SCANNING A QR CODE
  // useEffect(() => {
  //   if (state && state.uuid && state.distanceEnabled === false) {
  //     mutation.mutate({
  //       key: state.key,
  //       uuid: state.uuid,
  //       lat: 0.0,
  //       lng: 0.0,
  //       method: 'Access Key',
  //     });
      
  //   }
  // }, [state]);

  return (
    <>
      <Header />
      <Typography
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '20px',
        }}
        variant='h5'
      >
        {statusMessage}
      </Typography>

      {keyValidated === 'success' &&
        deviceKey?.success === true && ( // deviceKey?.online === true
          <Scan deviceKey={deviceKey} />
        )}

      {state && state.uuid && <Scan deviceKey={state} />}

      {/* {keyValidated === 'success' &&
        deviceKey.success === true &&
        deviceKey.online !== true && (
          <Typography
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '20px',
            }}
            variant='h5'
          >
            Device might be Offline
          </Typography>
        )} */}
    </>
  );
}
